<template>
    <a class="c-splash-page link" role="link" aria-label="Weiter" href="#" @click.prevent="goToNextPage" @touchend.prevent="goToNextPage">
        <slot></slot>
    </a>
</template>

<script>
import { pageMixin } from '@xpedeo/core'
import { t as throttle, E as EventHub } from '@xpedeo/core/dist/index2.js'
// import EventHub from '../../util/EventHub'

export default {
  mixins: [pageMixin],
  data () {
    return {
      shouldIgnoreClick: false
    }
  },
  created () {
    console.log('Custom XpSplash screen page', this.pageData)
    this.goToNextPage = throttle(this.goToNextPage)
    // if ()
  },
  mounted () {
    this.$xp.history.addHomePage(this.$route.params.id, this.$route.path, this.pageData.Title, 0, [])
    this.$el.focus()
    EventHub.Bus.$on(EventHub.TYPES.CLICKED_HOTSPOT_SPLASHSCREEN, () => {
      console.log('event hub got message CLICKED_HOTSPOT_SPLASHSCREEN and ignoring next click')
      this.shouldIgnoreClick = true
    })
  },
  methods: {
    goToNextPage (e) {
      console.log('on click gotonextpage', e)
      if (this.shouldIgnoreClick) {
        console.log('ignoring click in splash screen', this.shouldIgnoreClick)
        this.shouldIgnoreClick = !this.shouldIgnoreClick
        return
      }
      // if (this.$xp.content.showPreloadModal) {
      //   return
      // }
      console.log('goToNextPage()')
      if (this.pageData.NextPageID) {
        this.$router.push('/page/' + this.pageData.NextPageID)
      } else {
        console.warn('Splash screen has no next page')
      }
    }
  },
  watch: {
    shouldIgnoreClick (oldVal, newVal) {
      console.log('shouldIgnoreClick changed', oldVal, newVal)
    },
    '$xp.content.showPreloadModal': {
      handler (v) {
        console.log('showPreloadModal Watcher in splash screen', v)
        this.shouldIgnoreClick = !this.shouldIgnoreClick
      }
    }
  }
}
</script>
